import { useEffect, useState } from "react"
import { CarritoContext } from "./CarritoContext"
import { formatter } from "../resources/funciones";

export const CarritoProvider = ({ children }) => {

    const [carrito, setCarrito] = useState([])

    const [mostrarCarrito, setMostrarCarrito] = useState(false);

    const [compraValida, setCompraValida] = useState(false)

    const abrirCarrito = () => {
        setMostrarCarrito(true)
        // setIsNavOpen(false)
    }

    const cerrarCarrito = () => {
        setMostrarCarrito(false)
    }

    const aniadirProducto = (productoAniadido, contador) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];

        const existeProductoCarrito = carritoLS?.find(c => c?.ProdCod == productoAniadido?.ProdCod)

        let dataGuardar

        if(existeProductoCarrito) {
            // actualiza la cantidad de productos en caso ya haya sido agregado un producto que ya ha sido agregado antes al carrito
            let cantidadActualizada = existeProductoCarrito?.Cantidad + contador 
            const carritoActualizado = carritoLS?.map( (c)=>{

                if(c?.ProdCod === productoAniadido?.ProdCod){
                    return {
                        ...c,
                        Cantidad: cantidadActualizada,
                        ProdPrecN: productoAniadido?.ProdPrecN
                    }
                }
                return c
            })

            setCarrito(carritoActualizado)
            dataGuardar = carritoActualizado

        }else{
            dataGuardar = [
                ...carritoLS,
                {
                    ...productoAniadido,
                    Cantidad: contador,
                    ProdPrecN: productoAniadido?.ProdPrecN
                }
            ]

            setCarrito(
                [
                    ...carritoLS,
                    {
                        ...productoAniadido,
                        Cantidad: contador,
                        ProdPrecN: productoAniadido?.ProdPrecN
                    }
                ]
            )
    
        }

        localStorage.setItem('carrito', JSON.stringify(dataGuardar));
        abrirCarrito(true)
    }

    const eliminarProducto = (e, id) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        const carritoActual = carritoLS?.filter(c => c?.ProdCod != id)
        localStorage.setItem('carrito', JSON.stringify(carritoActual));
        setCarrito(carritoActual)
    }

    const aniadirCantidad = (id) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        const carritoActualizado = carritoLS?.map((c) => {

            if (c?.ProdCod == id) {

                return {
                    ...c,
                    Cantidad: c?.Cantidad + 1
                }
            }
            return c
        })

        setCarrito(carritoActualizado)
        localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
    }

    const disminuirCantidad = (id) => {

        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        const cantidadProductoEncontrado = carrito?.find(c => c?.ProdCod == id)?.Cantidad

        if (cantidadProductoEncontrado <= 1) {
            // La cantidad se queda como minimo a 1
        } else {

            const carritoActualizado = carritoLS?.map((c) => {

                if (c?.ProdCod == id) {

                    return {
                        ...c,
                        Cantidad: c?.Cantidad - 1
                    }
                }
                return c
            })
            setCarrito(carritoActualizado)
            localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
        }
    }


    const vaciarCarrito = () => {
        localStorage.setItem('carrito', JSON.stringify([]));
        setCarrito([])
    }

       
    const totalCarrito = () => {
        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];

        const total = carritoLS.reduce((total, producto) => {
            const precio = producto.ProdPrecD ? parseFloat(producto.ProdPrecD) : parseFloat(producto.ProdPrecN);
            return total + precio * producto.Cantidad;
        }, 0);
        return formatter.format(total);
    };

    useEffect(() => {
        const carritoLS = JSON.parse(localStorage.getItem('carrito')) || [];
        setCarrito(carritoLS)
    }, [])

    useEffect(() => {
        totalCarrito()
    }, [carrito])


    return (
        <CarritoContext.Provider
            value={{
                carrito, setCarrito,
                mostrarCarrito, setMostrarCarrito,
                abrirCarrito, cerrarCarrito,
                aniadirProducto, eliminarProducto,
                vaciarCarrito,
                aniadirCantidad, disminuirCantidad,
                totalCarrito,
                compraValida, setCompraValida
            }}
        >
            {children}
        </CarritoContext.Provider>
    )
}
