import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Carrito, Contacto, DatosEnvio, Experiencias, FinalCompra, GaleriaPage, Inicio, MensajeMayorEdad, Nosotros, PagoIziPay, ProductoDescripcion, Tienda } from "../components"
import { Footer, NavBar } from "../ui";
import { ScrollToTop } from "../components/ScrollToTop";

export const AppRouter = () => {

    const location = useLocation()
    const ruta = location.pathname.split("/")[1]

    return (
        <>  
            <ScrollToTop />
            {
                (ruta != "" && ruta != "compra" && ruta != "pagar-izipay")&& (
                    <NavBar />
                )
            }
            <div className="bg-pagina">
                <Routes>
                    <Route path="/" element={<MensajeMayorEdad />} />
                    <Route path="inicio" element={<Inicio />} />
                    <Route path="nosotros" element={<Nosotros />} />
                    <Route path="tienda" element={<Tienda />} />
                    <Route path="experiencias" element={<Experiencias />} />
                    <Route path="galeria" element={<GaleriaPage />} />
                    <Route path="contacto" element={<Contacto />} />
                    <Route path="producto/:id" element={<ProductoDescripcion />} />
                    <Route path="carrito" element={<Carrito />} />
                    <Route path="compra" element={<DatosEnvio />} />
                    <Route path="pagar-izipay" element={<PagoIziPay />} />
                    <Route path="final-compra" element={<FinalCompra />} />
                    <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
            </div>
            {
                (ruta != "" && ruta != "compra" && ruta != "pagar-izipay") && (
                    <Footer />
                )
            }
        </>
    )
}
