import { useContext, useEffect } from "react"
import { CarritoContext } from "../context/CarritoContext"
import { Link, useNavigate } from "react-router-dom"

export const FinalCompra = () => {

  const { compraValida } = useContext(CarritoContext)
  const navigate = useNavigate()

  useEffect(() => {
    if(!compraValida){
      navigate("/inicio")
    }
  }, [compraValida])
  

  return (
    <>
      <div className="container d-flex justify-content-center" style={{ minHeight: "calc(93vh - 113px)" }}>
        {
          compraValida && (
            <div style={{padding: "0 5%"}}>
                <h2 className="text-center mt-5 mb-5">¡Compra completada con éxito!</h2>
                <div style={{color: "#555555"}}>Gracias por elegir nuestros piscos. Esperamos que disfrutes al máximo de cada momento con nuestros productos de alta calidad.</div>
                <div style={{color: "#555555"}}>¡Salud y gracias por confiar en nosotros! 🍹🎉</div>
                <div style={{color: "#555555"}}>Si tienes alguna duda sobre tu pedido, no dudes en comunicarte al correo <Link>pedidos@altosdeazpitia.com</Link></div>
                <div style={{color: "#555555"}} className="text-center mt-5">Equipo de Altos de Azpitia</div>
              </div>
          )
        }
      </div >
      <div className="h-auto mb-0">
        <div className="div-franja" id="franjaimagen"></div>
      </div>
    </>
  )
}
