import { CarritoProvider } from "./context/CarritoProvider"
import { ProductoProvider } from "./context/ProductoProvider"
import { AppRouter } from "./router/AppRouter"

export const AltosDeAzpitiaApp = () => {
  return (
    <ProductoProvider>
      <CarritoProvider>
        <AppRouter />
      </CarritoProvider>
    </ProductoProvider>
  )
}
