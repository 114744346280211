import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Card } from './Card';

export const GaleriaProductos = ({ listaProductos, columnas = 3, espaciado = 30 }) => {

  const numeroProductos = listaProductos?.length || 0

  const [numeroColumnas, setNumeroColumnas] = useState(() => {
    if (window.innerWidth <= 768) {
      return 1;
    }
    return columnas;
  });

  const handleWindowResize = () => {
    if (window.innerWidth <= 768) {
      setNumeroColumnas(1);
    } else {
      setNumeroColumnas(columnas);
    }
  };

  useEffect(() => {
    handleWindowResize(); // Llamar a la función al cargar la página

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (

      <Swiper
        slidesPerView={numeroColumnas}
        spaceBetween={espaciado}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        navigation={true}
        loop= {true}
        className="mySwiper"
        id={(numeroColumnas==3 && numeroProductos<3) ? 'swiperPersonalizado' : ''}
      >
        {
          listaProductos?.map((p) => (
            <SwiperSlide key={p.ProdCod}><Card {...p} p={p}/></SwiperSlide>
          ))
        }
      </Swiper>

  )
}

