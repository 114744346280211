export const formatter = new Intl.NumberFormat('es-PE', {
    style: 'currency',
    currency: 'PEN',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  });

  
export const isValidEmail = email =>
// eslint-disable-next-line no-useless-escape
/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
  email.trim()
);

export const guardarLocalStorage = (obj, key) => {
  localStorage.setItem(key, JSON.stringify(obj));
}

export const obtenerLocalStorage = (key) => {
  const datosLSStr = localStorage.getItem(key);
  const datosLSObj = JSON.parse(datosLSStr);
  return datosLSObj
}

export const limpiarLocalStorage = (key) => {
  localStorage.removeItem(key);
}