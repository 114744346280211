
import { useNavigate } from 'react-router-dom'

import { Carousel } from './Carousel'
import { GaleriaProductos } from './GaleriaProductos'
import { useContext } from 'react'
import { ProductoContext } from '../context/ProductoContext'

export const Inicio = () => {

  const { listaProductos } = useContext(ProductoContext)

  const navigate = useNavigate();

  const irTienda = () => {
    navigate('/tienda')
  }

  return (
    <>
      {/* CAROUSEL */}
      <Carousel />

      {/* NUESTRA PRODUCCION */}
      <div className="container mt-2">
        <h2 className="mt-4">Nuestra Producción</h2>
        <h3 className="mt-4">Todos los productos</h3>
        <section>
          <GaleriaProductos listaProductos={listaProductos} />
        </section>

        <div className="d-flex justify-content-center mt-3">
          <button type="button" className="btn bg-secundario text-white" onClick={irTienda}>Ir a la tienda</button>
        </div>
      </div >
      <div className="h-auto mb-0 mt-3">
        <div className="div-franja" id="franjaimagen"></div>
      </div>
    </>
  )
}
