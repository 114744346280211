import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import { useMobileDetection } from '../hook/useMobileDetection';

export const GaleriaImagenes = ({ imagenes, width="", inicioSlide=0 }) => {

  const isMobile = useMobileDetection()

  return (
    <div className="container-fluid">
      <div className="h-100 d-flex align-items-center">
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        lazyPreloadPrevNext={0}
        autoplay={{
          delay: 8000,
        }}
        rewind={true}
        cssMode={false}
        navigation={true}
        initialSlide={inicioSlide}
        
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiper align-items-center"
        id="swiper-id"
      >
        {
          imagenes.map((img) => (

            <SwiperSlide key={img.id} className={isMobile ? "":width}>
              <img src={img.url} className="img-thumbnail" style={isMobile ? {height: '209px', width: '375px'}:{height:'100%', width:'100%'}}/>
            </SwiperSlide>
          ))
        }
      </Swiper >
      </div>
      
    </div>
  );
}
