import ReactPlayer from 'react-player'
import { useMobileDetection } from '../hook/useMobileDetection'

// 800x400
export const Experiencias = () => {

  const isMobile = useMobileDetection()
  const video = '/video/video.mp4'

  return (
    <>
    <div className="w-100">
      <div style={{ height: isMobile ? "auto" : "calc(100vh - 113px)" }}>
        <ReactPlayer
          url={video}
          controls
          volume={0.1}
          className="d-flex"
          playing={true}
          loop={true}
          width={'100%'}
          height={'100%'}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
        />
      </div>

    </div>
    <div className="h-auto mb-0 mt-3">
    <div className="div-franja" id="franjaimagen"></div>
  </div>
  </>
  )
}
