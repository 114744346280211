import { useEffect } from "react"

export const ContadorProducto = ({idProducto, margen="", contador, setContador}) => {


    const aniadir = () => {
        setContador(contador + 1)
    }

    const disminuir = () => {

        if (contador <= 1) {
            setContador(1)
        } else {
            setContador(contador - 1)
        }
    }

    useEffect(() => {
        setContador(1); // Reiniciar el contador a 1 cuando cambia el idProducto
      }, [idProducto]);

    return (
        <div className={`num-cantidad ${margen!="" && margen}`}>
            <div className="disminuir" onClick={disminuir}><span>-</span></div>
            <div className="numero"><span>{contador}</span></div>
            <div className="aumentar" onClick={aniadir}><span>+</span></div>
        </div>
    )
}
