import { useNavigate } from "react-router-dom"
import { useMobileDetection } from "../hook/useMobileDetection"

export const MensajeMayorEdad = () => {

    const isMobile = useMobileDetection()

    const navigate = useNavigate()

    const inicioApp = () => {
        navigate('/inicio')
    }

    return (
        <div className="bg-principal vh-100 d-flex justify-content-center align-items-center">
            <div className={`bg-pagina ${isMobile ? "p-3" : "p-5"} m-auto mx-3`}>
                <h1 className="text-center" style={{ color: "#324158", fontSize: '60px' }}>¿Eres mayor de edad?</h1>
                <button type="button" className="btn bg-principal w-100 text-white mx-auto" onClick={inicioApp}>Sí, acepto ser mayor de edad en mi país de residencia.</button>
                <div className="mt-3">
                    <div className="text-center" style={{ color: "rgb(128,128,128)" }}>Tienes que ser mayor de edad para navegar en nuestra página web</div>
                    <div className="text-center" style={{ color: "rgb(128,128,128)" }}>TOMAR BEBIDAS ALCOHÓLICAS EN EXCESO ES DAÑINO</div>
                </div>

            </div>
        </div>
    )
}
