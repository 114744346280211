import { imagenesSeccionNosotros } from "../data/data"
import { GaleriaImagenes } from "./GaleriaImagenes"

export const Nosotros = () => {
  return (
    <>
      <div style={{ minHeight: "calc(100vh - 113px)" }} className="d-flex justify-content-center align-items-center">
        <div className="container h-100">
          <div className="row align-items-center mt-4">
            <div className="col-12 col-lg-5">
              <h2>Ubicación e historia</h2>
              <p style={{ textAlign: "justify" }}>Las uvas y mostos que utilizamos para nuestros piscos provienen de unas tierras
                relativamente altas (120 m.s.n.m) ubicadas a unos 10 kms. del océano Pacífico y que
                se ubican en una meseta irrigada con aguas del río Mala gracias a una obra de ingeniería (canal
                de
                San Andrés) labrada sobre la propia cordillera que baja hacia el mar</p>
              <p style={{ textAlign: "justify" }}>Los primeros agricultores de esta nueva irrigación fueron los propios picapedreros de Yura
                (Arequipa)
                que luego de terminada la obra se quedaron como colonos. Ellos y unas familias italo-peruanas
                que ya
                estaban instaladas en la costa en Puerto Viejo, empezaron a sembrar uvas pisqueras (Quebranta e
                Italia) y a destilar excelentes mostos. Como dato histórico, en nuestro fundo tenemos una vid de
                la
                cepa "negra criolla" que tiene más de 100 años y que, según cuentan, fue sembrada por un
                ingeniero
                supervisor de la obra del canal llamado Miguel Dellepiane.</p>
              <p style={{ textAlign: "justify", marginBottom: "0.5rem" }}>
                Los actuales propietarios herederos de esta rica tradición ahora representada en la marca "Altos
                de
                Azpitia" asumimos el compromiso de mantener los más altos estándares de calidad y transparencia
                en
                nuestro pisco, y a continuar con las ya tradicionales vendimias en marzo de cada año.
              </p>
            </div>
            <div className="col-12 col-lg-7 mb-2">
              <GaleriaImagenes imagenes={imagenesSeccionNosotros} />
            </div>
          </div>
        </div>
      </div>
      <div className="h-auto mb-0 mt-3">
        <div className="div-franja" id="franjaimagen"></div>
      </div>
    </>
  )
}
