import { GaleriaImagenes } from './GaleriaImagenes';
import { imagenesSeccionGaleria } from '../data/data';
import { useMobileDetection } from '../hook/useMobileDetection';

export const GaleriaPage = () => {

    const isMobile = useMobileDetection();

    return (
        <>
            <div style={{ minHeight: isMobile ? 'auto' : "calc(100vh - 113px)", position: "relative", maxHeight: "494px" }}>
                <div className="d-flex" style={{ minHeight: isMobile? "":"calc(100vh - 113px)", padding: isMobile ? "" : "10px 110px"}}>
                    <GaleriaImagenes imagenes={imagenesSeccionGaleria} width={'w-auto'} inicioSlide={1} />
                </div>
            </div>
            <div className="h-auto mb-0">
                <div className="div-franja" id="franjaimagen"></div>
            </div>
        </>
    );
}
