import { useForm } from "react-hook-form"
import { enviarCorreo } from "../api/enviarCorreo";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isValidEmail } from "../resources/funciones";

const tema = "colored"

const notifyCorrecto = () => toast.success('Correo enviado 📧', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: tema,
});

const notifyError = () => toast.error('Falló al enviar correo', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: tema,
});

export const Contacto = () => {

  const { register, handleSubmit, formState: { errors }, reset } = useForm()

  const imagen = "/image/contacto/imagen1.jpg"

  const capitalize = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleEmailValidation = email => {
    const isValid = isValidEmail(email);
    return isValid;
  };

  const onSubmit = async (data) => {

    const mensajeCorreo = data.mensaje.replace("\n", " ")

    const dataPOST = {
      nombre: capitalize(data.nombre).trim(),
      apellido: capitalize(data.apellido).trim(),
      email: data.email,
      telefono: data.telefono.trim(),
      mensaje: mensajeCorreo.trim()
    }

    const { status, message } = await enviarCorreo(dataPOST)

    if (status == 1) {
      notifyCorrecto()
      reset()
    } else {
      notifyError()
    }

  }

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "calc(93vh - 113px)"}}>
          <div className="row" style={{padding: "15px 0"}}>
            <form onSubmit={handleSubmit(onSubmit)} className="col-12 col-lg-6">
              <h2 className="text-center mt-0 mb-4">Únete a nuestra comunidad</h2>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label className="form-label">Nombre *</label>
                  <input
                    {...register("nombre", { required: true })}
                    type="text"
                    className="form-control mb-1"
                    autoComplete="off"
                    style={{ textTransform: "uppercase" }} />
                  {errors.nombre && errors.nombre.type === "required" && <p role="alert" className="error">Ingrese su nombre</p>}
                </div>
                <div className="col-12 col-lg-6">
                  <label className="form-label">Apellido *</label>
                  <input
                    {...register("apellido", { required: true })}
                    type="text"
                    className="form-control mb-1"
                    autoComplete="off"
                    style={{ textTransform: "uppercase" }} />
                  {errors.apellido && errors.apellido.type === "required" && <p role="alert" className="error">Ingrese su apellido</p>}
                </div>
                <div className="col-12 col-lg-6">
                  <label className="form-label">Email *</label>
                  <input
                    {...register("email", { required: true, validate: handleEmailValidation })}
                    type="text"
                    className="form-control mb-1"
                  /* autoComplete="off"  */ />
                  {errors.email && errors.email.type === "required" && <p role="alert" className="error">Ingrese su email</p>}
                  {errors.email && errors.email.type === "validate" && <p role="alert" className="error">Email incorrecto</p>}
                </div>
                <div className="col-12 col-lg-6">
                  <label className="form-label">Teléfono de contacto</label>
                  <input
                    {...register("telefono")}
                    type="text"
                    className="form-control mb-1"
                    autoComplete="off" />
                </div>
                <div className="col-12">
                  <label className="form-label">Mensaje</label>
                  <textarea
                    {...register("mensaje")}
                    className="form-control mb-1"
                    placeholder="Escribenos"
                    id="floatingTextarea"
                  ></textarea>
                </div>
                <div className="d-flex justify-content-center mt-3 mb-2">
                  <button type="button submit" className="btn bg-principal w-100 text-white">Enviar</button>
                </div>
              </div>
            </form>
            <div className="col-12 col-lg-6">
              <div>
                <img src={imagen} className="img-thumbnail w-100 h-100" />
              </div>

            </div>
          </div>
      </div >
      <div className="h-auto mb-0">
        <div className="div-franja" id="franjaimagen"></div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={tema}
      />
    </>
  )
}
