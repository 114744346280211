import { Link, useNavigate } from 'react-router-dom';
import { useMobileDetection } from '../hook/useMobileDetection';

let fecha = new Date()
let anio = fecha.getFullYear()

export const Footer = () => {

    const facebook = "/facebook.png"
    const instagram = "/instagram.png"
    const isMobile = useMobileDetection()

    return (
        <footer className="bg-principal">
            <div className="container">
                <div className="d-flex align-items-center">
                    <div className={`row w-100 m-1 ${isMobile ? "mt-3 mb-2" : "mt-5 mb-4"}`}>
                        <div className={`col-md-4 d-flex align-items-center justify-content-center ${isMobile ? "p-2 mb-2" : "p-3"}`} style={{background:"white"}}>
                            <div>
                                    <h5 className="card-title">Monte Carmelo Azpitia</h5>
                                    <div className="text-center">
                                        <Link className="card-text" style={{ fontSize: '14px' }} to={`/contacto`}>pedidos@altosdeazpitia.com</Link>
                                    </div>
                            </div>
                        </div>
                        <div className={`col-md-4 d-flex align-items-center justify-content-center border border-1 ${isMobile ? "p-2 mb-2" : "p-3"}`} style={{background:"white"}}>
                                <div>
                                    <h5 className="card-title">Horario de la Bodega</h5>
                                    <div className="text-center">
                                        <Link className="card-text" style={{ fontSize: '14px' }} to={`/contacto`}>Reservar</Link>
                                    </div>
                                </div>
                        </div>
                        <div className={`col-md-4 d-flex align-items-center justify-content-center ${isMobile ? "p-2 mb-2" : "p-3"}`} style={{background:"white"}}>
                                <div>
                                    <h5 className="card-title">Horario de envíos</h5>
                                    <p className="card-text" style={{ fontSize: '14px' }}>Lunes : 9am - 6pm</p>
                                    <p className="card-text" style={{ fontSize: '14px' }}>Sábado:  9am - 1pm</p>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-6 border-end text-end">
                        <Link to={"https://www.facebook.com/altosdeazpitia"} className=" text-white text-decoration-underline pe-3" target="_blank"><img src={facebook} alt="facebook" style={{ width: '30px', height: 'auto' }} className="img-card" /></Link>
                    </div>
                    <div className="col-6 text-start">
                        <Link to={"https://www.instagram.com/altosdeazpitia/"} target="_blank" className="text-white text-decoration-underline ps-3"><img src={instagram} alt="facebook" style={{ width: '30px', height: 'auto' }} className="img-card" /></Link>
                    </div>
                </div>
                <div className="pb-2">
                    <div className="text-center" style={{ color: "#808B96" }}>TOMAR BEBIDAS ALCOHÓLICAS EN EXCESO ES DAÑINO</div>
                    <div className="text-center" style={{ color: "#808B96" }}>© {anio} Altos de Azpitia</div>
                </div>
            </div>


        </footer>

    )
}