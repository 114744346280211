const rutaImgProducto = '/image/tienda/productos_img_png'

export const imagenesCarousel = [
    {id:1, url:"/image/inicio/carousel/imagen1.png"},
    {id:2, url:"/image/inicio/carousel/imagen2.png"},
    {id:3, url:"/image/inicio/carousel/imagen3.png"},
    {id:4, url:"/image/inicio/carousel/imagen4.png"}
]

export const listaProductos = [
    {
        id:1, 
        tipo: "mosto-verde",
        nombre:"Pisco Mosto Verde Puro Mollar", 
        precioNormal:89, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco2.png`,
        nuevo: true,
        informacion: "Suprema combinación para un chilcano premium."
    },
    {
        id:2,
        tipo: "mosto-verde", 
        nombre:"Pisco Mosto Verde Puro Italia", 
        precioNormal:89, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco3.png`,
        nuevo: true,
        informacion: "La engreída y orgullo de la casa. Aroma floral y sabor aterciopelado."
    },
    {
        id:3, 
        tipo: "mosto-verde",
        nombre:"Pisco Mosto Verde Acholado Mollar Italia", 
        precioNormal:89, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco1.png`,
        nuevo: true,
        informacion: "Suprema combinación para un chilcano premium."
    },
    {
        id:4, 
        tipo: "puro",
        nombre:"Pisco Puro Italia", 
        precioNormal:49, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco4.png`,
        nuevo: false,
        informacion: "Cepa tradicional y aromática. Ideal como bajativo"
    },
    {
        id:5, 
        tipo: "puro",
        nombre:"Pisco Puro Quebranta", 
        precioNormal:49, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco5.png`,
        nuevo: false,
        informacion: "Nuestra cepa más tradicional. Tómalo como abrebocas o en cóctel como pisco sour."
    },
    {
        id:6, 
        tipo: "puro",
        nombre:"Pisco Puro Mollar", 
        precioNormal:49, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco6.png`,
        nuevo: false,
        informacion: "Cepa aromática redescubierta. Excelente para un buen pisco sour."
    },
    {
        id:7, 
        tipo: "acholado",
        nombre:"Pisco Acholado Quebranta - Italia", 
        precioNormal:49, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco7.png`,
        nuevo: false,
        informacion: "El blend más tradicional y clásico. Recomendado para chilcano y usado también para pisco sour."
    },
    {
        id:8, 
        tipo: "acholado",
        nombre:"Pisco Acholado Mollar - Italia", 
        precioNormal:49, 
        precioOferta:"", 
        imagen:`${rutaImgProducto}/pisco8.png`,
        nuevo: false,
        informacion: "Sabroso para el chilcano y cuando quieras combinar lo mejor de las dos sensaciones: aroma y sabor."
    }
]

export const imagenesSeccionNosotros = [
    {id:1, url:"/image/nosotros/img1.png"},
    {id:2, url:"/image/nosotros/img2.png"},
    {id:3, url:"/image/nosotros/img3.png"},
    {id:4, url:"/image/nosotros/img4.png"},
    {id:5, url:"/image/nosotros/img5.png"},
]

export const imagenesSeccionGaleria = [
    {id:1, url:"/image/galeria/galeria_imagen1.jpg"},
    {id:2, url:"/image/galeria/galeria_imagen2.jpg"},
    {id:3, url:"/image/galeria/galeria_imagen3.jpg"},
    {id:4, url:"/image/galeria/galeria_imagen4.jpg"},
    {id:5, url:"/image/galeria/galeria_imagen5.jpg"},
    {id:6, url:"/image/galeria/galeria_imagen6.jpg"},
    {id:7, url:"/image/galeria/galeria_imagen7.jpg"},
    {id:8, url:"/image/galeria/galeria_imagen8.jpg"},
    {id:9, url:"/image/galeria/galeria_imagen9.jpg"},
    {id:10, url:"/image/galeria/galeria_imagen10.jpg"},
    {id:11, url:"/image/galeria/galeria_imagen11.jpg"},
    {id:12, url:"/image/galeria/galeria_imagen12.jpg"},
    {id:13, url:"/image/galeria/galeria_imagen13.jpg"},
    {id:14, url:"/image/galeria/galeria_imagen14.png"},

]