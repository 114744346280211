
import { useContext, useEffect, useState } from "react"
import { listaProductos } from "../data/data"
import { GaleriaProductos } from "./GaleriaProductos"
import { ProductoContext } from "../context/ProductoContext"

export const Tienda = () => {

  const { listaPiscosPuros, listaPiscosAcholados, listaPiscosMostoVerde } = useContext(ProductoContext)

  const lazo = '/image/lazo.png'

  return (
    <>
      <div className="container">
        <div className="row">
          <h2 className="mt-4 text-center">Piscos Puros</h2>
          <div className="col-12 mb-2">
            <img src={lazo} className="img-fluid rounded mx-auto d-block" alt="..." />
          </div>
          <section className="col-12 my-2">
            <GaleriaProductos listaProductos={listaPiscosPuros} />
          </section>

          <h2 className="mt-4 text-center">Piscos Acholados</h2>
          <div className="col-12 mb-2">
            <img src={lazo} className="img-fluid rounded mx-auto d-block" alt="..." />
          </div>
          <section className="col-12 my-2">
            <GaleriaProductos listaProductos={listaPiscosAcholados} />
          </section>

          <h2 className="mt-4 text-center"> Piscos Mosto Verde</h2>
          <div className="col-12 mb-2">
            <img src={lazo} className="img-fluid rounded mx-auto d-block" alt="..." />
          </div>
          <section className="col-12 my-2">
            <GaleriaProductos listaProductos={listaPiscosMostoVerde} />
          </section>
        </div>
      </div>
      <div className="h-auto mb-0 mt-3">
        <div className="div-franja" id="franjaimagen"></div>
      </div>
    </>
  )
}
