
import { useContext, useEffect } from "react"
import { BsFillTrashFill } from "react-icons/bs"
import { IoIosArrowForward } from "react-icons/io"
import { CarritoContext } from "../context/CarritoContext"
import { formatter } from "../resources/funciones"
import { Link, useNavigate } from "react-router-dom"

export const CarritoSidebar = () => {

    const { carrito, cerrarCarrito, eliminarProducto, vaciarCarrito, mostrarCarrito, setMostrarCarrito, disminuirCantidad, aniadirCantidad, totalCarrito } = useContext(CarritoContext)

    const navigate = useNavigate()

    const irComprar = () => {
        navigate("/carrito")
        setMostrarCarrito(false)
    }

    return (
        <div className={`cart ${mostrarCarrito && "active"}`}>
            <div className="contenedor-cart-title">
                <IoIosArrowForward id="close-cart" onClick={cerrarCarrito} />
                <h2 className="cart-title">
                    Carrito
                </h2>
            </div>
            {
                carrito?.length > 0 ? (
                    <>
                        <div className="cart-content">
                            {
                                carrito.length > 0 && carrito.map((c) => (
                                    <div className="cart-box" key={c?.ProdCod}>
                                        <div className="bg-image-cart">
                                            <img src={c?.ProdImg} alt="..." className="cart-img" />
                                        </div>

                                        <div className="detail-box">
                                            <div className="cart-product-title">{c?.ProdNom}</div>
                                            <div className="cart-price">
                                                {
                                                    (!c?.ProdPrecD) ? (
                                                        <strong style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecN)}</strong>
                                                    ) : (
                                                        <>
                                                            <s style={{ fontSize: "14px", color: "rgb(128,128,128)" }}>{formatter.format(c?.ProdPrecN)}</s>
                                                            <strong className="ms-2" style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecD)}</strong>
                                                        </>
                                                    )
                                                }
                                            </div>

                                                <div className="num-cantidad" style={{ width: '75px', height: '30px' }}>
                                                    <div className="disminuir" onClick={(e) => disminuirCantidad(c?.ProdCod)}><span>-</span></div>
                                                    <div className="numero"><span>{c?.Cantidad}</span></div>
                                                    <div className="aumentar" onClick={(e) => aniadirCantidad(c?.ProdCod)}><span>+</span></div>
                                                </div>

                                        </div>
                                        <BsFillTrashFill className="cart-remove" onClick={(e) => eliminarProducto(e, c?.ProdCod)} />
                                    </div>
                                ))
                            }

                        </div>

                        <div className="total">
                            <div className="total-title">Total</div>
                            <div className="total-price">{totalCarrito()}</div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
                            <button type="button" className="btn btn-sm btn-danger text-white me-2" onClick={vaciarCarrito}>Vaciar carrito</button>
                            <button type="button" className="btn btn-sm bg-principal text-white" onClick={irComprar}>Comprar ahora</button>
                        </div>
                    </>
                ) : (
                    <Link className="nav-link text-center mt-5" to="/tienda">
                        Añadir productos al carrito
                    </Link>
                )
            }
        </div>
    )
}
