import { api } from "./configApi";

export const enviarCorreo = async (dataPOST) => {
    try {
        
        const res = await api.post("api/mail/enviarCorreo", dataPOST)

        if (res.status === 200) {
            return {
              status: 1,
              message: 'Correo enviado'
            };
          } else {
            return {
              status: 0,
              message: 'Error en el servidor'
            };
          }
    } catch (error) {
        return {
            status: 0,
            message: 'Error en el servidor'
        }
    }
}
