import { useContext, useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs"
import { CarritoContext } from "../context/CarritoContext";
import { formatter, guardarLocalStorage } from "../resources/funciones";
import { useMobileDetection } from "../hook/useMobileDetection";
import { Link, useNavigate } from "react-router-dom";
import { getPreciosActualizados } from "../api/azpitia";

export const Carrito = () => {

    const { carrito, eliminarProducto, disminuirCantidad, aniadirCantidad, totalCarrito } = useContext(CarritoContext)

    const [carritoActualizado, setCarritoActualizado] = useState([])

    const isMobile = useMobileDetection()
    const navigate = useNavigate()

    const subTotalPorProducto = (producto) => {
        const precio = producto?.ProdPrecD ? parseFloat(producto?.ProdPrecD) : parseFloat(producto?.ProdPrecN);
        const subTotal = precio * producto?.Cantidad
        return formatter.format(subTotal);
    }

    const irComprar = () => {
        if (carrito?.length > 0) {
            navigate("/compra")
        }

    }

    // Función para actualizar los nombres y precios en el carrito
    const actualizarProducto = (productoCarrito, preciosActualizados) => {
        for (let productoPrecio of preciosActualizados) {
            if (productoCarrito.ProdCod === productoPrecio.ProductoC) {
                productoCarrito.ProdNom = productoPrecio.ProductoNombre;
                productoCarrito.ProdPrecN = productoPrecio.ProductoPrecioN;
                productoCarrito.ProdPrecD = productoPrecio.ProductoPrecioD;
                break;
            }
        }
        return productoCarrito;
    }

    const obtenerPreciosActualizados = async (dataPOST) => {
        const { preciosActualizados } = await getPreciosActualizados(dataPOST)

        // Actualizar nombres y precios en el carrito usando map
        let carritoActualizado = carrito.map(c => actualizarProducto(c, preciosActualizados));
        setCarritoActualizado(carritoActualizado)

        guardarLocalStorage(carritoActualizado, "carrito")
    }

    useEffect(() => {

        const listaIdProductos = carrito.map(c => ({
            ProductoC: c.ProdCod
        }))
        const dataPOST = {
            list: listaIdProductos
        }
        obtenerPreciosActualizados(dataPOST)
    }, [carrito])

    return (
        <div className="container" style={{ minHeight: isMobile ? "" : "685px" }}>
            <div className="row carrito-contenedor">
                <div className="col-md-8 detalle">
                    <div className="detalle-titulo">
                        <h3 className="mt-4">Mi carrito</h3>
                    </div>
                    {
                        carritoActualizado?.length > 0 ? (
                            carritoActualizado?.map(c => (
                                <div className="detalle-producto" key={c?.ProdCod}>
                                    <div className="row detalle-item">
                                        <div className="col-md-3 detalle-img">
                                            <img src={c?.ProdImg} alt="..." className="img-producto  border border-1 border-rounded" />
                                        </div>

                                        <div className={`col-md-4 detalle-box ${isMobile && "text-center"}`}>
                                            <div className={`cart-product-title`}>{c?.ProdNom}</div>
                                            <div className="cart-price">
                                                {
                                                    (!c?.ProdPrecD) ? (
                                                        <strong style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecN)}</strong>
                                                    ) : (
                                                        <>
                                                            <s style={{ fontSize: "14px", color: "rgb(128,128,128)" }}>{formatter.format(c?.ProdPrecN)}</s>
                                                            <strong className="ms-2" style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecD)}</strong>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-2 d-flex" style={{padding: "2px 0"}}>
                                            <div className="detalle-cantidad p-auto w-100">
                                                <div className={`num-cantidad ${isMobile && "m-auto"}`} style={{ maxHeight: '30px', width: isMobile ? "250px" : "" }}>
                                                    <div className="disminuir" onClick={(e) => disminuirCantidad(c?.ProdCod)}><span>-</span></div>
                                                    <div className="numero"><span>{c?.Cantidad}</span></div>
                                                    <div className="aumentar" onClick={(e) => aniadirCantidad(c?.ProdCod)}><span>+</span></div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-2 detalle-subtotal" style={{ padding: "5px 0" }}>
                                            {subTotalPorProducto(c)}
                                        </div>
                                        <div className="col-md-1 detalle-remover">
                                            <BsFillTrashFill className="cart-remove" onClick={(e) => eliminarProducto(e, c?.ProdCod)} />
                                        </div>
                                    </div>

                                </div>
                            ))
                        ) : (
                            <Link className="nav-link text-center mt-3" to="/tienda">
                                Añadir productos al carrito
                            </Link>
                        )
                    }
                    <div className="detalle-adicional">

                    </div>
                </div>
                <div className="col-md-4 resumen-pedido">
                    <div className="resumen-titulo mt-4">
                        <h3>Resumen del pedido</h3>
                    </div>

                    <div className="resumen-precios">
                        <div className="resumen-subtotal">
                            <div className={`cart-product-title`}>Subtotal</div>
                            <div className={`cart-product-title`}>{totalCarrito()}</div>
                        </div>
                        <div className="resumen-subtotal">
                            <div className={`cart-product-title`}>Envio</div>
                            <div className={`cart-product-title`}>{formatter.format(0)}</div>
                        </div>
                    </div>
                    <div className="resumen-subtotal mt-2">
                        <div className={`cart-product-title`}>Total</div>
                        <div className={`cart-product-title`}>{totalCarrito()}</div>
                    </div>

                    <div className="resumen-procesar-comprar mt-4 mb-3">
                        <button type="button" className="btn bg-principal w-100 text-white" onClick={irComprar}  disabled={carrito?.length > 0 ? false : true}>Procesar compra</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
