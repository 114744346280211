
import { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { FaShoppingCart } from "react-icons/fa"
import { CarritoSidebar } from '../components';
import { CarritoContext } from '../context/CarritoContext';

export const NavBar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { abrirCarrito, carrito } = useContext(CarritoContext)
  const [contadorDelCarrito, setContadorDelCarrito] = useState()

  const logo = "/image/logo.png"

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const accionAbrirCarrito = () => {
    abrirCarrito()
    closeNav()
  }

  const numeroProductosDistintos = () => {
    setContadorDelCarrito(carrito.length)
  }

  useEffect(() => {
    numeroProductosDistintos()
  }, [carrito])
  

  return (
    <nav className="navbar navbar-dark navbar-expand-md bg-principal">
      <div className="container">
        <Link className="navbar-brand col-4 m-1" to="/inicio">
          <img src={logo} alt="Logo Altos de Azpitia" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded={isNavOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
          onClick={toggleNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`col-7 justify-content-end collapse navbar-collapse ${isNavOpen ? 'show' : 'close'
            }`}
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <NavLink
              className={({ isActive }) => `nav-link me-2 w-auto ${isActive ? 'active' : ''}`}
              to="/inicio"
              onClick={closeNav}
            >
              Inicio
            </NavLink>
            <NavLink className="nav-link me-2" to="/nosotros" onClick={closeNav}>
              Nosotros
            </NavLink>
            <NavLink className="nav-link me-2" to="/tienda" onClick={closeNav}>
              Tienda
            </NavLink>
            <NavLink className="nav-link me-2" to="/experiencias" onClick={closeNav}>
              Experiencias
            </NavLink>
            <NavLink className="nav-link me-2" to="/galeria" onClick={closeNav}>
              Galeria
            </NavLink>
            <NavLink className="nav-link" to="/contacto" onClick={closeNav}>
              Contacto
            </NavLink>
            <NavLink id="cart-icono" className="nav-link" onClick={accionAbrirCarrito}>
                <FaShoppingCart fontSize={25} />
                <div className="contador-cart">{contadorDelCarrito}</div>
            </NavLink>
          </ul>
        </div>
      </div>
      <CarritoSidebar />
    </nav>
  );
};
