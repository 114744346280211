import { useForm, Controller } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { formatter, guardarLocalStorage, isValidEmail, obtenerLocalStorage } from "../resources/funciones"
import React, { useContext, useEffect, useState } from "react"
import { CarritoContext } from "../context/CarritoContext"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getUbigeo, registrarSolicitud } from "../api/azpitia"
import { ToastContainer, toast } from 'react-toastify';

const tema = "colored"

const notifyError = () => toast.error('Error en el servidor', {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: tema,
});

export const DatosEnvio = () => {

    const logo = "/image/logo-envio.jpg"

    const [listaUbigeo, setListaUbigeo] = useState([])
    const [loading, setLoading] = useState(false)

    const datosLS = obtenerLocalStorage('cliente')

    const { register, handleSubmit, formState: { errors }, reset, control, watch } = useForm( {
        defaultValues: {
            email: datosLS?.email || "",
            nombre: datosLS?.nombre || "",
            apellido: datosLS?.apellido || "",
            telefono: datosLS?.telefono || "",
            pais: datosLS?.pais || "Peru",
            ciudad: datosLS?.ciudad || "",
            direccion: datosLS?.direccion || "" ,
            cod_postal: datosLS?.cod_postal || "",
        }
    } )
    const { carrito, totalCarrito } = useContext(CarritoContext)
    const navigate = useNavigate()

    const onSubmitDetalles = async (data) => {

        setLoading(true)
        
        guardarLocalStorage(data, 'cliente')

        const dataDepProvDis = data?.depProvDis?.tipo || ""

        const listaProductos = carrito?.map((p) => (
            {
                ProdCod: p.ProdCod,
                Cantidad: p.Cantidad,
            }
        ))
        let departamento = "";
        let provincia = "";
        let distrito = "";

        if (dataDepProvDis != "") {
            departamento = dataDepProvDis.split("/")[0]
            provincia = dataDepProvDis.split("/")[1]
            distrito = dataDepProvDis.split("/")[2]
        }

        const dataDetalles = {
            SolEmail: data.email.trim(),
            SolNom: data.nombre.trim().toUpperCase(),
            SolApe: data.apellido.trim().toUpperCase(),
            SolTel: data.telefono.trim(),
            SolPais: data.pais.trim().toUpperCase(),
            SolDep: departamento.toUpperCase(),
            SolProv: provincia.toUpperCase(),
            SolCiud: (distrito != "" ? distrito.toUpperCase() : data.ciudad.trim().toUpperCase()),
            SolDire: data.direccion.trim().toUpperCase(),
            SolCodPost: data.cod_postal.trim(),
            list: listaProductos || []
        }

        const { status, message, token, codigo_solicitud } = await registrarSolicitud(dataDetalles)

        if (status == 1) {
            navigate("/pagar-izipay", {
                state: {
                    token,
                    dataDetalles,
                    codigo_solicitud
                }
            })
        } else {
            notifyError()
            setLoading(false)
        }


    }

    const handleEmailValidation = email => {
        const isValid = isValidEmail(email);
        return isValid;
    };

    const obtenerListaUbigeo = async () => {
        const { status, ubigeo } = await getUbigeo()

        if (status == 1) {
            setListaUbigeo(ubigeo)
        } else {
            setListaUbigeo([])
        }
    }

    useEffect(() => {
        obtenerListaUbigeo();
    }, []);

    return (
        <>
            {/* Navbar de compra */}
            <nav className="navbar-compra">
                <div className="container contenedor-logo">
                    <Link className="navbar-brand col-4 m-1 logo-envio me-4" to="/inicio">
                        <img src={logo} alt="Logo Altos de Azpitia" />
                    </Link>
                    <h2 style={{ fontSize: "20px", marginBottom: "0" }}>Proceso de Compra</h2>
                </div>
            </nav>

            {/* Formulario de compra */}
            <div className="container">
                <div className="row carrito-contenedor">
                    <div className="col-lg-8 compra">
                        <div className="compra-titulo">
                            <h3 className="mt-4">Datos de envio</h3>
                        </div>
                        <form onSubmit={handleSubmit(onSubmitDetalles)} className="p-1">

                            <div className="form-item-envio">
                                <label className="form-label">Email para confirmar el pedido *</label>
                                <input
                                    {...register("email", { required: true, validate: handleEmailValidation })}
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                                {errors.email && errors.email.type === "required" && <p role="alert" className="error">Ingrese su email</p>}
                                {errors.email && errors.email.type === "validate" && <p role="alert" className="error">Email incorrecto</p>}
                            </div>
                            <div className="form-item-envio">
                                <label className="form-label">Nombre *</label>
                                <input
                                    {...register("nombre", { required: true })}
                                    type="text"
                                    className="form-control form-control-sm input-form"
                                />
                                {errors.nombre && errors.nombre.type === "required" && <p role="alert" className="error">Ingrese su nombre</p>}
                            </div>
                            <div className="form-item-envio">
                                <label className="form-label">Apellido *</label>
                                <input
                                    {...register("apellido", { required: true })}
                                    type="text"
                                    className="form-control form-control-sm input-form"
                                />
                                {errors.apellido && errors.apellido.type === "required" && <p role="alert" className="error">Ingrese su apellido</p>}
                            </div>
                            <div className="form-item-envio">
                                <label className="form-label">Teléfono *</label>
                                <input
                                    {...register("telefono", { required: true })}
                                    type="text"
                                    className="form-control form-control-sm input-form"
                                />
                                {errors.telefono && errors.telefono.type === "required" && <p role="alert" className="error">Ingrese su teléfono</p>}
                            </div>
                            <div className="form-item-envio">
                                <label className="form-label">País *</label>
                                <select {...register("pais", { required: true })} className="form-select form-select-sm">
                                    <option value="Peru">Perú</option>
                                </select>
                                {errors.pais && errors.pais.type === "required" && <p role="alert" className="error">Ingrese país</p>}
                            </div>
                            {
                                watch("pais") == "Peru" ? (
                                    <div className="form-item-envio">
                                        <label className="form-label">Distrito *</label>
                                        <Controller
                                            name="depProvDis"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    size="small"
                                                    id="combo-box-demo"
                                                    options={listaUbigeo}
                                                    getOptionLabel={(option) => option.tipo}
                                                    // getOptionValue={(option) => option.id} // Obtener el valor del 'id' del distrito
                                                    renderInput={(params) => <TextField {...params} placeholder="Ingrese distrito" />}
                                                    onChange={(e, newValue) => field.onChange(newValue)} // Actualizar el valor del campo "distrito" cuando se seleccione una opción
                                                />
                                            )}
                                        />
                                        {errors.depProvDis && errors.depProvDis.type === "required" && <p role="alert" className="error">Ingrese Distrito</p>}
                                    </div>


                                ) : (
                                    <div className="form-item-envio">
                                        <label className="form-label">Ciudad *</label>
                                        <input
                                            {...register("ciudad", { required: true })}
                                            type="text"
                                            className="form-control form-control-sm input-form"
                                        />
                                        {errors.ciudad && errors.ciudad.type === "required" && <p role="alert" className="error">Ingrese ciudad</p>}
                                    </div>
                                )
                            }

                            <div className="form-item-envio">
                                <label className="form-label">Dirección *</label>
                                <input
                                    {...register("direccion", { required: true })}
                                    type="text"
                                    className="form-control form-control-sm input-form"
                                />
                                {errors.direccion && errors.direccion.type === "required" && <p role="alert" className="error">Ingrese su dirección</p>}
                            </div>

                            <div className="form-item-envio">
                                <label className="form-label">Código postal</label>
                                <input
                                    {...register("cod_postal")}
                                    type="text"
                                    className="form-control form-control-sm input-form"
                                />
                            </div>
                        </form>
                        <div className="d-flex justify-content-center mt-3 mb-4">
                            <button
                                type="button submit"
                                onClick={handleSubmit(onSubmitDetalles)}
                                className="btn bg-principal w-100 text-white"
                                style={{ cursor: 'pointer' }}
                                disabled={carrito?.length > 0 ? false : true}
                            >
                                Continuar&nbsp;&nbsp;
                                {
                                    loading && (
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="visually-hidden" >Loading...</span>
                                        </div>
                                    )
                                }

                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 resumen-pedido">
                        <div className="compra-titulo">
                            <h3 className="mt-4">Resumen del pedido</h3>
                            <Link to={`/carrito`} className="mt-4"><h3>Editar carrito</h3></Link>
                        </div>

                        <div className="compra-carrito">
                            {
                                carrito?.length > 0 ? (
                                    <>
                                        <div className="cart-content">
                                            {
                                                carrito.length > 0 && carrito.map((c) => (
                                                    <div className="cart-box" key={c?.ProdCod}>
                                                        <div className="bg-image-cart border border-1 border-rounded">
                                                            <img src={c?.ProdImg} alt="..." className="cart-img" />
                                                        </div>

                                                        <div className="detail-box">
                                                            <div className="cart-product-title">{c?.ProdNom}</div>
                                                            <div className="cart-price">
                                                                {
                                                                    (!c?.ProdPrecD) ? (
                                                                        <strong style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecN)}</strong>
                                                                    ) : (
                                                                        <>
                                                                            <s style={{ fontSize: "14px", color: "rgb(128,128,128)" }}>{formatter.format(c?.ProdPrecN)}</s>
                                                                            <strong className="ms-2" style={{ color: "#052F3E" }}>{formatter.format(c?.ProdPrecD)}</strong>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="numero">Cantidad: <span style={{ color: "#052F3E" }}>{c?.Cantidad}</span></div>

                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>

                                        <div className="total">
                                            <div className="total-title">Total</div>
                                            <div className="total-price">{totalCarrito()}</div>
                                        </div>
                                    </>
                                ) : (
                                    <Link className="nav-link text-center mt-5" to="/tienda">
                                        Añadir productos al carrito
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={tema}
            />
        </>
    )
}
