import { useEffect, useState } from "react"
import { ProductoContext } from "./ProductoContext"
import { getProductos } from "../api/azpitia"

export const ProductoProvider = ({ children }) => {

    const [listaProductos, setListaProductos] = useState([])
    const [listaPiscosPuros, setListaPiscosPuros] = useState()
    const [listaPiscosAcholados, setListaPiscosAcholados] = useState()
    const [listaPiscosMostoVerde, setListaPiscosMostoVerde] = useState()

    const obtenerProductos = async () => {
        const { status, productos, piscosPuros, piscosAcholados, piscosMostoVerde } = await getProductos()
        if (status == 1) {
            setListaProductos(productos)
            setListaPiscosPuros(piscosPuros)
            setListaPiscosAcholados(piscosAcholados)
            setListaPiscosMostoVerde(piscosMostoVerde)
        } else {
            setListaProductos([])
            setListaPiscosPuros([])
            setListaPiscosAcholados([])
            setListaPiscosMostoVerde([])
        }
    }

    useEffect(() => {
        obtenerProductos()
    }, [])

    return (
        <ProductoContext.Provider
            value={{ listaProductos, listaPiscosPuros, listaPiscosAcholados, listaPiscosMostoVerde }}
        >
            {children}
        </ProductoContext.Provider>
    )
}
